import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';
import linkResolver from 'helpers/linkResolver';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
  Dot,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const getWidth = () =>
  (typeof window !== 'undefined' && window.innerWidth) ||
  (typeof window !== 'undefined' && document.documentElement.clientWidth) ||
  (typeof window !== 'undefined' && document.body.clientWidth);

function LatestDevelopments(props) {
  const { data, items } = props;
  const [width, setWidth] = useState(getWidth());
  const [SlideTotal, setSlideTotal] = useState(1);

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeListener);
    }
    // clean up function
    return () => {
      // remove resize listener
      typeof window !== 'undefined' &&
        window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    if (width < 900) {
      setSlideTotal(1);
    }
    if (width > 900) {
      setSlideTotal(2);
    }
    if (width > 1500) {
      setSlideTotal(3);
    }
  });

  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      {data.title && data.title.raw && (
        <Box component="div" color="text.primary" mb={4}>
          <RichText render={data.title.raw} htmlSerializer={htmlSerializer} />
        </Box>
      )}

      {items && items.length > 0 && (
        <Box>
          <CarouselProvider
            //isIntrinsicHeight={true}
            naturalSlideWidth={100}
            naturalSlideHeight={130}
            totalSlides={items.length}
            visibleSlides={SlideTotal}
            infinite={true}
          >
            <Box component={Slider} style={{ height: '100%', width: '100%' }}>
              {items.map((item, index) => {
                return (
                  <Slide index={index} key={index}>
                    <Link
                      to={`${
                        item.development.type
                          ? linkResolver(item.development)
                          : ''
                      }`}
                    >
                      <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                        px={1.5}
                      >
                        <Box
                          style={{
                            position: 'absolute',
                            height: '100%',
                            width: 'calc(100% - 24px)',
                            backgroundColor: '#000',
                            opacity: 0.25,
                          }}
                        />
                        <Box
                          position="absolute"
                          display="flex"
                          flexDirection="column"
                          px={4}
                        >
                          <Typography
                            variant="h3"
                            component="p"
                            align="center"
                            style={{
                              color: '#fff',
                              textTransform: 'uppercase',
                            }}
                          >
                            {item.development.document.data.title.text}
                          </Typography>
                          <Typography
                            variant="h5"
                            component="p"
                            align="center"
                            style={{
                              fontWeight: 'bold',
                              color: '#fff',
                              textTransform: 'uppercase',
                            }}
                          >
                            {
                              item.development.document.data
                                .development_location.text
                            }
                          </Typography>
                        </Box>
                        {item &&
                          item.development &&
                          item.development.document &&
                          item.development.document.data &&
                          item.development.document.data.preview_image &&
                          item.development.document.data.preview_image.url && (
                            <img
                              className="w-100"
                              style={{ height: '100%' }}
                              src={`${item.development.document.data.preview_image.url}&ar=1:1.2&fit=crop`}
                              alt={
                                item.development.document.data.preview_image.alt
                                  ? item.development.document.data.preview_image
                                      .alt
                                  : ''
                              }
                            />
                          )}
                      </Box>
                    </Link>
                  </Slide>
                );
              })}
            </Box>
            <Box
              style={{
                top: '50%',
                left: '1vw',
                border: 0,
                outline: 0,
                padding: 0,
                position: 'absolute',
              }}
            >
              <ButtonBack
                style={{
                  background: 'transparent',
                  border: 0,
                  display: 'flex',
                }}
              >
                <ArrowBackIosIcon
                  style={{ color: '#fff', fontSize: '3.5rem' }}
                />
              </ButtonBack>
            </Box>
            <Box
              style={{
                top: '50%',
                right: '1vw',
                border: 0,
                outline: 0,
                padding: 0,
                position: 'absolute',
              }}
            >
              <ButtonNext
                style={{
                  background: 'transparent',
                  border: 0,
                  display: 'flex',
                }}
              >
                <ArrowForwardIosIcon
                  style={{ color: '#fff', fontSize: '3.5rem' }}
                />
              </ButtonNext>
            </Box>
          </CarouselProvider>
        </Box>
      )}
    </Container>
  );
}

LatestDevelopments.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default LatestDevelopments;
