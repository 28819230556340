import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';
import linkResolver from 'helpers/linkResolver';

export const Background = styled.div`
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.image})`};
  background-color: #000;
`;

const styles = {
  image: {
    transition: 'transform 0.3s ease-in-out',
    transform: 'scale(1)',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  },
};

function ThreeColImageGrid(props) {
  const { data, items, classes } = props;
  const borderWeight = data.border_weight ? data.border_weight : 0;
  const borderColor = data.border_color ? data.border_color : 'transparent';
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      {items && (
        <div className="spaced-grid-wrapper">
          <Grid container spacing={6}>
            {items.map((item, key) => {
              return (
                <Grid item xs={12} md={4} key={key}>
                  <Link to={linkResolver(item.link ? item.link : '')}>
                    <Box
                      style={{
                        borderWidth: borderWeight,
                        borderColor: borderColor,
                        borderStyle: 'solid',
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        position="relative"
                        width="100%"
                        display="flex"
                        alignItems="center"
                      >
                        {item.title && item.title.raw && (
                          <Box
                            position="absolute"
                            component="div"
                            color="text.secondary"
                            px={4}
                            zIndex={10}
                          >
                            <RichText
                              render={item.title.raw}
                              htmlSerializer={htmlSerializer}
                            />
                          </Box>
                        )}
                        {item.image && item.image.url && (
                          <img
                            src={`${item.image.url}&ar=2:1.5&fit=crop`}
                            alt={item.image.alt}
                            className={`w-100 ${classes.image}`}
                          />
                        )}
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
    </Container>
  );
}

ThreeColImageGrid.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThreeColImageGrid);
