import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Context } from 'store/provider';
import { withStyles } from '@material-ui/styles';
import Link from 'gatsby-link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import linkResolver from 'helpers/linkResolver';

const styles = (theme) => ({
  wrapper: {
    position: 'fixed',
    backgroundColor: '#282827',
    width: '100%',
    zIndex: '240',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    height: '0px',
    overflow: 'hidden',
    '&.open': {
      height: '100%',
      opacity: 1,
    },
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
  },
});

function BurgerMenu(props) {
  const { classes } = props;
  const [state, dispatch] = useContext(Context);
  const menuToggle = () => {
    dispatch({ type: 'SET_MENU', payload: !state.menu });
  };
  return (
    <StaticQuery
      query={graphql`
        query BurgurMenuQuery {
          prismicGlobalLayout {
            data {
              burger_menu {
                link_name
                link {
                  uid
                  type
                  id
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className={`${classes.wrapper} ${state.menu ? 'open' : 'closed'}`}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {data.prismicGlobalLayout.data.burger_menu && (
              <React.Fragment>
                {data.prismicGlobalLayout.data.burger_menu.map((item, key) => {
                  return (
                    <Box py={0.5} key={key}>
                      <Typography
                        component={Link}
                        variant="h5"
                        to={linkResolver(item.link)}
                        onClick={menuToggle}
                        className={classes.link}
                      >
                        {item.link_name}
                      </Typography>
                    </Box>
                  );
                })}
              </React.Fragment>
            )}
          </Box>
        </div>
      )}
    />
  );
}

BurgerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BurgerMenu);
