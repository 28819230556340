import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'gatsby-link';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhoneIcon from '@material-ui/icons/Phone';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  icon: {
    color: theme.palette.text.secondary,
    width: '30px',
  },
});

function SecondaryNav(props) {
  const { classes } = props;
  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <Box display="none" justifyContent="center" mr={{ xs: 2, lg: 4 }}>
        <Box display="flex" alignItems="center" mr={1}>
          <AccountCircleIcon className={classes.icon} />
        </Box>
        <Box display={{ xs: 'none', lg: 'flex' }}>
          <Typography
            component={Link}
            variant="button"
            color="textSecondary"
            className={classes.link}
            to=""
          >
            CUSTOMER PORTAL
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" alignItems="center" mr={0.5}>
          <a href="tel:01844295100" style={{ display: 'flex' }}>
            <PhoneIcon className={classes.icon} />
          </a>
        </Box>
        <Box display={{ xs: 'none', lg: 'flex' }}>
          <Typography
            component="p"
            variant="button"
            color="textSecondary"
            className={classes.link}
          >
            01844 295100
          </Typography>
        </Box>
        <Box display={{ xs: 'none', lg: 'flex' }} ml={4}>
          <Typography
            component={Link}
            to="/contact-us"
            variant="button"
            color="textSecondary"
            className={classes.link}
          >
            CONTACT US
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

SecondaryNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SecondaryNav);
