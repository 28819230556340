import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function Sitemap(props) {
  const { data } = props;
  return (
    <Box>
      <Box mb={6}>
        <Typography variant="h3">Sitemap</Typography>
      </Box>
      <img className="w-100" src={data.url} alt={data.alt ? data.alt : ''} />
    </Box>
  );
}

Sitemap.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sitemap;
