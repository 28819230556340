import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import linkResolver from 'helpers/linkResolver';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { FooterMenu } from 'components/slices';

import Logo from 'images/logo.svg';
import { Typography } from '@material-ui/core';

import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

const styles = (theme) => ({
  footerWrapper: {
    borderTop: `4px solid ${theme.palette.primary.main}`,
  },
  item: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 0,
    },
    '&.item-0': {
      borderRight: `0px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
});

function Footer(props) {
  const { classes, data } = props;
  const doc = data.prismicGlobalLayout.data;
  return (
    <Box
      className={classes.footerWrapper}
      component="footer"
      bgcolor="background.secondary"
      mt={10}
    >
      <Container maxWidth={process.env.CONTAINER_WIDTH}>
        <Box py={10}>
          <div className="spaced-grid-wrapper">
            <Grid container spacing={10}>
              <Grid item xs={12} md={6} className={`item-0 ${classes.item}`}>
                {doc.body.map((item, i) => {
                  switch (item.slice_type) {
                    case 'footer_menu':
                      return (
                        <React.Fragment key={i}>
                          <FooterMenu data={item.primary} items={item.items} />
                        </React.Fragment>
                      );
                    default:
                      return null;
                  }
                })}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Box
                    component={Grid}
                    item
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box mb={{ xs: 6, md: 0 }}>
                      <Logo />
                    </Box>
                  </Box>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" mb={4}>
                      <Box mr={4}>
                        <PhoneIcon className={classes.icon} />
                      </Box>
                      <div>
                        <Typography component="h5" color="textSecondary">
                          {data.prismicGlobalLayout.data.telephone_number}
                        </Typography>
                        <Typography component="h6">
                          {data.prismicGlobalLayout.data.opening_hours}
                        </Typography>
                      </div>
                    </Box>
                    <Box display="flex">
                      <Box mr={4}>
                        <MailOutlineIcon className={classes.icon} />
                      </Box>
                      <div>
                        <Typography component="h5" color="textSecondary">
                          {data.prismicGlobalLayout.data.email_address}
                        </Typography>
                        <Typography component="h6">
                          {data.prismicGlobalLayout.data.opening_hours}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box mb={2} display="flex">
                        {data.prismicGlobalLayout.data.legal_menu.map(
                          (item, key) => {
                            if (item.link.link_type == 'Document') {
                              return (
                                <Box mr={2} key={key}>
                                  <Typography
                                    component={Link}
                                    color="textPrimary"
                                    variant="caption"
                                    to={`${
                                      item.link.type
                                        ? linkResolver(item.link)
                                        : ''
                                    }`}
                                  >
                                    {item.link.link_type == 'Document'
                                      ? item.link.document.data.page_title.text
                                      : 'Consumer Code'}
                                  </Typography>
                                </Box>
                              );
                            }
                            if (
                              item.link.link_type == 'Media' &&
                              item.link.raw.name ==
                                'Consumer-Code-Scheme-Fourth-Edition_April-2017_15502_large-print.pdf'
                            ) {
                              return (
                                <Box mr={1} key={key}>
                                  <Typography
                                    component="a"
                                    color="textPrimary"
                                    variant="caption"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={item.link.raw.url}
                                  >
                                    Consumer Code
                                  </Typography>
                                </Box>
                              );
                            }
                            if (
                              item.link.link_type == 'Media' &&
                              item.link.raw.name == 'Customer Charter FINAL.pdf'
                            ) {
                              return (
                                <Box mr={1} key={key}>
                                  <Typography
                                    component="a"
                                    color="textPrimary"
                                    variant="caption"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={item.link.raw.url}
                                  >
                                    Customer Charter
                                  </Typography>
                                </Box>
                              );
                            }
                          }
                        )}
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography component="p" variant="caption">
                          {data.prismicGlobalLayout.data.copyright.text}
                        </Typography>
                        <Box display="flex" mt={4}>
                          <Box>
                            <a
                              href="https://www.instagram.com/rectoryhomes/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <InstagramIcon style={{ color: '#fff' }} />
                            </a>
                          </Box>
                          <Box mx={2}>
                            <a
                              href="https://twitter.com/rectoryhomes?lang=en"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <TwitterIcon style={{ color: '#fff' }} />
                            </a>
                          </Box>
                          <Box>
                            <a
                              href="https://www.facebook.com/rectoryhomes/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FacebookIcon style={{ color: '#fff' }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box mt={4}>
                        <a
                          href="https://www.inetdigital.co.uk"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#fff', fontSize: '1rem' }}
                        >
                          Website by iNet Digital
                        </a>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
