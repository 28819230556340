import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

const styles = (theme) => ({
  item: {
    '&.item-0': {
      borderRight: `0px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
});

function ColumnContentBlock(props) {
  const { data, items, classes } = props;
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <Box>
        {data.title && data.title.raw && (
          <Box component="div" color="text.primary" align="left" mb={4}>
            <RichText render={data.title.raw} htmlSerializer={htmlSerializer} />
          </Box>
        )}
        {items && (
          <div className="spaced-grid-wrapper content-column-block">
            <Grid container spacing={10}>
              {items.map((item, key) => {
                return (
                  <Grid
                    key={key}
                    item
                    xs={12}
                    md={6}
                    className={`item-${items.length > 2 ? 'key' : key} ${
                      classes.item
                    }`}
                  >
                    <Box
                      component="div"
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      alignItems="center"
                      color="text.secondary"
                      className={classes.contentWrapper}
                    >
                      <RichText
                        render={item.content_block.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </Box>
    </Container>
  );
}

ColumnContentBlock.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ColumnContentBlock);
