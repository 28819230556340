import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Link from 'gatsby-link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import linkResolver from 'helpers/linkResolver';

import RectoryIdent from 'images/RectoryIdent.svg';

const styles = (theme) => ({
  link: {
    background: theme.palette.text.primary,
  },
  linkItem: {
    textDecoration: 'none',
    '& span': {
      transition: 'all 0.3s ease-in-out',
      opacity: '1',
    },
    '&:hover span': {
      opacity: '0.5',
    },
  },
  logo: {
    minWidth: '60px',
    maxWidth: '150px',
    display: 'block',
    margin: '0 auto',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  border: {
    border: '2px solid',
  },
  opacity: {
    opacity: 0.5,
  },
});

function DevelopmentIcon(props) {
  const { item, border, expanded, portfolio, classes } = props;
  var itemConst;
  var link = '/';

  if (item?.development?.document) {
    itemConst = item.development.document.data;
  }

  if (item?.node?.data) {
    itemConst = item.node.data;
  }

  if (item?.development?.type == 'development') {
    link = linkResolver(item.development);
  }

  if (item?.node?.type == 'development') {
    link = linkResolver(item.node);
  }

  return (
    <React.Fragment>
      <Box
        bgcolor="secondary.main"
        py={4}
        px={3}
        className={`${border ? classes.border : ''}`}
      >
        {item.node?.data?.distance && (
          <Box mb={1}>
            <Typography align="center" component="p" variant="body2">
              {Math.round(item.node.data.distance)} miles away
            </Typography>
          </Box>
        )}
        {!portfolio &&
          itemConst.development_logo &&
          itemConst.development_logo.url && (
            <Box mb={2}>
              <img
                src={itemConst.development_logo.url}
                alt=""
                className={classes.logo}
              />
            </Box>
          )}
        {portfolio && (
          <Box mb={2} display="flex" justifyContent="center">
            <RectoryIdent />
          </Box>
        )}
        {itemConst.title && itemConst.title.text && (
          <Box>
            <Typography
              variant="h5"
              component="p"
              align="center"
              className={classes.uppercase}
            >
              {itemConst.title.text}
            </Typography>
          </Box>
        )}
        {itemConst.development_location && itemConst.development_location.text && (
          <Box>
            <Typography
              variant="subtitle1"
              component="p"
              align="center"
              className={classes.uppercase}
            >
              {itemConst.development_location.text}
            </Typography>
          </Box>
        )}
        {expanded &&
          itemConst.development_short_detail &&
          itemConst.development_short_detail.text && (
            <Box mt={2}>
              <Typography component="p" variant="body2" align="center">
                {itemConst.development_short_detail.text}
              </Typography>
            </Box>
          )}
        {expanded &&
          itemConst.development_status &&
          itemConst.development_status.text && (
            <Box mt={2}>
              <Typography
                component="p"
                variant="caption"
                align="center"
                //className={classes.opacity}
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                {itemConst.development_status.text}
              </Typography>
            </Box>
          )}
      </Box>

      <Box className={classes.link} width="100%" py={1} align="center">
        <Link to={`${link}`} className={classes.linkItem}>
          <Typography component="span" variant="button" color="secondary">
            VIEW
          </Typography>
        </Link>
      </Box>
    </React.Fragment>
  );
}

DevelopmentIcon.propTypes = {
  item: PropTypes.object.isRequired,
  border: PropTypes.bool,
  expanded: PropTypes.bool,
  portfolio: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DevelopmentIcon);
