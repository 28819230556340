import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

function ContentBlock(props) {
  const { data } = props;
  return (
    <Container maxWidth="md">
      <Box component="div" color="text.secondary" align="center">
        <RichText render={data.content.raw} htmlSerializer={htmlSerializer} />
      </Box>
    </Container>
  );
}

ContentBlock.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContentBlock;
