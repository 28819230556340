import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Link from 'gatsby-link';
import linkResolver from 'helpers/linkResolver';
import { Typography } from '@material-ui/core';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

export const Background = styled.div`
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.image})`};
`;

const styles = (theme) => ({
  block: {
    width: '96%',
    marginLeft: '2%',
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    //transform: 'translateY(25%)',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginLeft: '5%',
      top: '0%',
      position: 'relative',
      transform: 'translateY(0%)',
    },
    [theme.breakpoints.up('lg')]: {
      width: '40%',
      marginLeft: '5%',
    },
  },
  textBlock: {
    textAlign: 'center',
    width: '96%',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
  blockOuter: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
    },
  },
  textBlockOuter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    backgroundColor: '#fff',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      backgroundColor: 'transparent',
      zIndex: 100,
    },
  },
  img: {
    width: '100%',
  },
  blockLink: {
    backgroundColor: theme.palette.primary.main,
    textDecoration: 'none',
  },
  link: {
    color: theme.palette.text.tonegrey,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 50,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  textTypeBlock: {},
  textTypePlain: {
    [theme.breakpoints.down('sm')]: {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
});

function FullWidthImage(props) {
  const { data, classes } = props;
  const bodyColor =
    data.content_layout_theme == 'Block' ? 'text.secondary' : 'text.secondary';
  const titleColor =
    data.content_layout_theme == 'Block' ? 'text.primary' : 'text.secondary';
  const showContent =
    (data.title && data.title.html) || (data.content && data.content.html);
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', md: 'unset' }}
      >
        <img
          src={`${data.image.url}&ar=16:9&fit=crop`}
          alt=""
          className={classes.img}
        />
        {showContent && (
          <React.Fragment>
            {data.content_layout_theme == 'Plain Text' && (
              <Box className={classes.overlay} />
            )}
            <Box
              className={`${
                data.content_layout_theme == 'Block'
                  ? classes.blockOuter
                  : classes.textBlockOuter
              }`}
            >
              {data.title && data.content && (
                <Box
                  p={6}
                  className={`${
                    data.content_layout_theme == 'Block'
                      ? classes.block
                      : classes.textBlock
                  }`}
                >
                  {data.title && data.title.raw && (
                    <Box
                      component="div"
                      color={titleColor}
                      align="center"
                      mb={4}
                    >
                      <RichText
                        render={data.title.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </Box>
                  )}
                  <Box
                    component="div"
                    color={bodyColor}
                    align="center"
                    className={`${
                      data.content_layout_theme == 'Block'
                        ? classes.textTypeBlock
                        : classes.textTypePlain
                    }`}
                  >
                    <RichText
                      render={data.content.raw}
                      htmlSerializer={htmlSerializer}
                    />
                  </Box>
                  {data.link && data.link.type && data.link.id && (
                    <Box display="flex" justifyContent="center" mt={4}>
                      <Box
                        component={Link}
                        py={1}
                        px={3}
                        className={classes.blockLink}
                        to={`${data.link.type ? linkResolver(data.link) : ''}`}
                      >
                        <Typography
                          component="span"
                          variant="body1"
                          className={classes.link}
                        >
                          Read More
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Container>
  );
}

FullWidthImage.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullWidthImage);
