import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const LargeItem = (props) => {
  const { data } = props;
  return (
    <Grid item xs={12} sm={4}>
      <Box position="relative" display="flex" alignItems="flex-end">
        <img
          className="w-100"
          src={`${data.image.url}&ar=1:1.2&fit=crop`}
          alt={data.image.alt ? data.image.alt : ''}
        />
        <Box position="absolute" p={4}>
          <Typography component="p" variant="body1" color="textSecondary">
            {data.name}
          </Typography>
          <Typography component="body2" variant="caption" color="textSecondary">
            {data.job_title}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

LargeItem.propTypes = {
  data: PropTypes.object.isRequired,
};

const SmallItem = (props) => {
  const { data } = props;
  return (
    <Grid item xs={6} md={4} lg={2}>
      <img
        className="w-100"
        src={`${data.image.url}&ar=1:1&fit=crop`}
        alt={data.image.alt ? data.image.alt : ''}
      />
      <Box>
        <Typography
          component="p"
          variant="body2"
          color="textSecondary"
          gutterBottom
        >
          {data.name}
        </Typography>
        <Typography component="p" variant="caption" color="textSecondary">
          {data.job_title}
        </Typography>
      </Box>
    </Grid>
  );
};

SmallItem.propTypes = {
  data: PropTypes.object.isRequired,
};

function MeetTheTeam(props) {
  const { data, items } = props;
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <div className="spaced-grid-wrapper">
        <Box component={Grid} container justifyContent="center" spacing={4}>
          {items.map((item, key) => {
            return !data.style ? (
              <LargeItem key={key} data={item} />
            ) : (
              <SmallItem key={key} data={item} />
            );
          })}
        </Box>
      </div>
    </Container>
  );
}

MeetTheTeam.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default MeetTheTeam;
