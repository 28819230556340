import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

function ContentImagesGrid(props) {
  const { data, items } = props;
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <div className="spaced-grid-wrapper">
        <Grid container spacing={4}>
          {data.content && data.content.raw && (
            <Box component={Grid} display="flex" item xs={12} lg={4}>
              <Box
                bgcolor="background.primary"
                display="flex"
                alignItems="center"
                p={10}
              >
                <Box component="div" color="text.secondary">
                  <RichText
                    render={data.content.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {items.map((item, key) => {
            return (
              <Box component={Grid} display="flex" key={key} item xs={6} lg={4}>
                <img
                  className="w-100"
                  src={`${item.image.url}&ar=1:1&fit=crop`}
                  alt={item.image.alt ? item.image.alt : ''}
                />
              </Box>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
}

ContentImagesGrid.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default ContentImagesGrid;
