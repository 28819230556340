import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Ident from 'images/ident.svg';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

export const Background = styled.div`
  min-height: 600px;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.image})`};
`;

const styles = {
  identWrapper: {
    transform: 'translateY(-50%)',
  },
  icon: {
    width: '80px',
    height: '80px',
  },
};

function ImageAndContentBlock(props) {
  const { data, classes } = props;
  if (!data.image.url && !data.content.html) return null;
  return (
    <Grid container>
      <Box
        position="absolute"
        width="100%"
        display="flex"
        justifyContent="center"
        className={classes.identWrapper}
      >
        <Ident className={classes.icon} />
      </Box>
      {data.image && data.image.url && (
        <Box
          component={Grid}
          item
          xs={12}
          lg={6}
          display="flex"
          order={{ xs: 0, lg: data.image_alignment ? 1 : 0 }}
        >
          <Background image={`${data.image.url}`} />
        </Box>
      )}
      {data.content && data.content.raw && (
        <Box component={Grid} item xs={12} lg={6} display="flex">
          <Box
            component="div"
            color="text.secondary"
            bgcolor="background.primary"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            py={12}
            px={8}
          >
            <RichText
              render={data.content.raw}
              htmlSerializer={htmlSerializer}
            />
          </Box>
        </Box>
      )}
    </Grid>
  );
}

ImageAndContentBlock.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageAndContentBlock);
