import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import linkResolver from 'helpers/linkResolver';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

const styles = (theme) => ({
  link: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
  },
});

function FooterMenu(props) {
  const { data, items, classes } = props;
  return (
    <Box mb={4}>
      {data.title && data.title.raw && (
        <Box component="div" mb={2}>
          <RichText render={data.title.raw} htmlSerializer={htmlSerializer} />
        </Box>
      )}
      {items && (
        <Grid container>
          {items.map((item, key) => {
            return (
              <Grid item key={key} xs={6} md={4}>
                <Link
                  to={`${item.link.type ? linkResolver(item.link) : ''}`}
                  className={classes.link}
                >
                  {item.link.document.data.page_title.text}
                </Link>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
}

FooterMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default withStyles(styles)(FooterMenu);
