import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from 'gatsby-link';
import linkResolver from 'helpers/linkResolver';
import { Typography } from '@material-ui/core';

function ForthcomingDevelopments(props) {
  const { items } = props;
  console.log(items);
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <Grid container spacing={4}>
        {items.map((item, key) => {
          return (
            <Box
              key={key}
              component={Grid}
              item
              xs={12}
              md={6}
              lg={4}
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Link
                to={`${
                  item.development.type ? linkResolver(item.development) : ''
                }`}
                style={{ position: 'relative', lineHeight: 0 }}
              >
                <Box
                  position="absolute"
                  style={{
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,0.3)',
                  }}
                />
                <Box
                  position="absolute"
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    top: 0,
                    left: 0,
                  }}
                >
                  <Typography
                    component="p"
                    variant="h3"
                    color="textSecondary"
                    align="center"
                    gutterBottom
                  >
                    {item.development.document.data.title.text}
                  </Typography>
                  {item.development?.document?.data?.development_location
                    ?.text && (
                    <Typography
                      component="p"
                      variant="h6"
                      color="textSecondary"
                      align="center"
                    >
                      {item.development.document.data.development_location.text}
                    </Typography>
                  )}
                  {item.development?.document?.data?.development_status
                    ?.text && (
                    <Typography
                      component="p"
                      variant="h6"
                      color="textSecondary"
                      align="center"
                    >
                      {item.development.document.data.development_status.text}
                    </Typography>
                  )}
                </Box>

                <img
                  className="w-100"
                  src={`${item.development.document.data.preview_image.url}&ar=16:10&fit=crop`}
                  alt={
                    item.development.document.data.preview_image.alt
                      ? item.development.document.data.preview_image.alt
                      : ''
                  }
                />
              </Link>
            </Box>
          );
        })}
      </Grid>
    </Container>
  );
}

ForthcomingDevelopments.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ForthcomingDevelopments;
