import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function LineSpacer(props) {
  const { data } = props;
  const thickness = data.thickness ? data.thickness : 1;
  const bgcolor = data.color ? data.color : '#BEA169';
  return <Box style={{ height: thickness + 'px', backgroundColor: bgcolor }} />;
}

LineSpacer.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LineSpacer;
