import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Context } from 'store/provider';
import Helmet from 'react-helmet';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import BurgerMenu from 'components/burgermenu';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

function Layout(props) {
  const { data, children } = props;
  const [state] = useContext(Context);
  return (
    <div id="app">
      <Helmet>
        <body
          className={`${
            state.menu || state.showLoader ? 'scrollFixed' : 'menuClosed'
          }`}
        />
      </Helmet>
      {state.showLoader && (
        <Box
          display="fixed"
          style={{
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: 'rgba(40, 40, 39, 0.7)',
            zIndex: 300,
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <GlobalStyle />
      <Head />
      <BurgerMenu />
      <Header />
      <main style={{ paddingTop: '200px' }}>{children}</main>
      <Footer data={data} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        prismicGlobalLayout {
          _previewable
          data {
            opening_hours
            telephone_number
            email_response_detail
            email_address
            copyright {
              html
              raw
              text
            }
            legal_menu {
              link {
                link_type
                raw
                uid
                type
                document {
                  ... on PrismicPage {
                    data {
                      page_title {
                        text
                      }
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicGlobalLayoutDataBodyFooterMenu {
                slice_type
                primary {
                  title {
                    html
                    raw
                    text
                  }
                }
                items {
                  link {
                    uid
                    type
                    document {
                      ... on PrismicPage {
                        id
                        data {
                          page_title {
                            html
                            raw
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
