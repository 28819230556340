import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Carousel from 'react-elastic-carousel';
import Box from '@material-ui/core/Box';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  itemWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  icon: {
    height: '100px',
  },
});

function ContentCarousel(props) {
  const { items, classes } = props;
  const breakPointsValues = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1280, itemsToShow: 3, itemsToScroll: 1 },
  ];
  return (
    <Carousel breakPoints={breakPointsValues} showArrows={false}>
      {items.map((item, key) => {
        return (
          <Box
            key={key}
            className={classes.itemWrapper}
            width="100%"
            mx={4}
            p={4}
          >
            <CarouselItem item={item} />
          </Box>
        );
      })}
    </Carousel>
  );
}

function CarouselItem(props) {
  const { item } = props;
  const [isClosed, setIsClosed] = useState(true);
  const hasIntro =
    item.intro_paragraph &&
    item.intro_paragraph.text &&
    item.intro_paragraph.html
      ? true
      : false;

  const toggle = () => {
    setIsClosed(!isClosed);
  };

  return (
    <Box>
      {item.icon && item.icon.url && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <img
            style={{ height: '100px' }}
            src={item.icon.url}
            alt={item.icon.alt ? item.icon.alt : item.title.text}
          />
        </Box>
      )}
      {item.title && item.title.raw && (
        <Box component="div" color="text.primary" align="left" mb={4}>
          <RichText render={item.title.raw} htmlSerializer={htmlSerializer} />
        </Box>
      )}
      {hasIntro && (
        <Box component="div" color="text.secondary" align="left">
          <RichText
            render={item.intro_paragraph.raw}
            htmlSerializer={htmlSerializer}
          />
        </Box>
      )}
      {item.content && item.content.raw && (
        <React.Fragment>
          <Box
            component="div"
            color="text.secondary"
            align="left"
            style={{
              display: hasIntro && isClosed ? 'none' : 'block',
            }}
            className={`${hasIntro ? 'hide' : 'show'}`}
          >
            <RichText
              render={item.content.raw}
              htmlSerializer={htmlSerializer}
            />
          </Box>
          {hasIntro && (
            <button
              onClick={() => toggle()}
              style={{ border: 0, background: 'none', cursor: 'pointer' }}
            >
              <Typography variant="body1" component="span" color="textPrimary">
                {isClosed ? 'Show more...' : 'Show less...'}
              </Typography>
            </button>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.array.isRequired,
};

ContentCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentCarousel);
