import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import Container from '@material-ui/core/Container';
import Masonry from '@mui/lab/Masonry';
import MasonryItem from '@mui/lab/MasonryItem';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import linkResolver from 'helpers/linkResolver';

const NewsArticles = graphql`
  query {
    allPrismicNewsArticle {
      edges {
        node {
          uid
          type
          data {
            article_type
            title {
              html
              raw
              text
            }
            listing_image {
              alt
              url
              dimensions {
                height
              }
            }
            images {
              image {
                alt
                url
              }
            }
            content {
              html
              raw
              text
            }
          }
        }
      }
    }
  }
`;

function NewsListing(props) {
  const { data } = props;
  const heights = [270, 350, 580, 410, 620, 500, 325, 432, 290, 600, 510];

  const height = (val) => {
    var answer;
    switch (val) {
      case 0:
      case 5:
      case 8:
      case 12:
        answer = 514;
        break;
      case 1:
      case 3:
      case 4:
        answer = 271;
        break;
      case 2:
        answer = 300;
        break;
      default:
        answer = heights[Math.floor(Math.random() * heights.length)];
    }
    return answer;
  };

  return (
    <StaticQuery
      query={`${NewsArticles}`}
      render={(newsData) => {
        const articles = newsData.allPrismicNewsArticle.edges;
        return (
          <Container maxWidth="lg">
            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
              {articles.map((item, key) => {
                var randomHeight =
                  heights[Math.floor(Math.random() * heights.length)];
                return (
                  <MasonryItem key={key}>
                    <Box
                      position="relative"
                      style={{
                        height: height(key),
                        overflow: 'hidden',
                        backgroundImage: `url(${item.node.data.listing_image.url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}
                    >
                      <Box
                        position="absolute"
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        }}
                      />
                      <Box
                        position="absolute"
                        style={{
                          left: '3rem',
                          bottom: '3rem',
                          width: 'calc(100% - 6rem)',
                        }}
                      >
                        <Typography
                          component="p"
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                        >
                          {item.node.data.title.text}
                        </Typography>
                        <Typography
                          component={Link}
                          to={linkResolver(item.node.uid ? item.node : '')}
                          variant="body2"
                          color="textSecondary"
                          style={{ textDecoration: 'underline' }}
                        >
                          Read More
                        </Typography>
                      </Box>
                    </Box>
                  </MasonryItem>
                );
              })}
            </Masonry>
          </Container>
        );
      }}
    />
  );
}

NewsListing.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NewsListing;
