import * as React from 'react';
var PrismicDOM = require('prismic-dom');
import { Elements } from 'prismic-richtext';

import linkResolver from 'helpers/linkResolver';

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.heading1:
      return (
        <h1 key={key} className="MuiTypography-root MuiTypography-h1">
          {children}
        </h1>
      );
    case Elements.heading2:
      return (
        <h2 key={key} className="MuiTypography-root MuiTypography-h2">
          {children}
        </h2>
      );
    case Elements.heading3:
      return (
        <h3 key={key} className="MuiTypography-root MuiTypography-h3">
          {children}
        </h3>
      );
    case Elements.heading4:
      return (
        <h4 key={key} className="MuiTypography-root MuiTypography-h4">
          {children}
        </h4>
      );
    case Elements.heading5:
      return (
        <h5 key={key} className="MuiTypography-root MuiTypography-h5">
          {children}
        </h5>
      );
    case Elements.heading6:
      return (
        <h6 key={key} className="MuiTypography-root MuiTypography-h6">
          {children}
        </h6>
      );
    case Elements.paragraph:
      return (
        <p
          key={key}
          className="MuiTypography-root MuiTypography-body1"
          style={{ marginBottom: '1rem' }}
        >
          {children}
        </p>
      );
    case Elements.preformatted:
      return <pre key={key}>{children}</pre>;
    case Elements.strong:
      return <strong key={key}>{children}</strong>;
    case Elements.em:
      return <em key={key}>{children}</em>;
    case Elements.listItem:
      return <li key={key}>{children}</li>;
    case Elements.oListItem:
      return <li key={key}>{children}</li>;
    case Elements.list:
      return <ul key={key}>{children}</ul>;
    case Elements.oList:
      return <ol key={key}>{children}</ol>;
    case Elements.image:
      var linkUrl = element.linkTo
        ? PrismicDOM.Link.url(element.linkTo, linkResolver)
        : null;
      var linkTarget =
        element.linkTo && element.linkTo.target ? element.linkTo.target : '';
      var wrapperClassList = [element.label || '', 'block-img'];
      var img = (
        <img
          src={element.url}
          alt={element.alt || ''}
          copyright={element.copyright || ''}
        />
      );
      return (
        <div
          key={key}
          target={linkTarget}
          className={wrapperClassList.join(' ')}
        >
          {linkUrl ? (
            <a className="body-text-link" href={linkUrl}>
              {img}
            </a>
          ) : (
            img
          )}
        </div>
      );
    case Elements.embed:
      return (
        <div
          key={key}
          data-oembed={element.oembed.embed_url}
          data-oembed-type={element.oembed.type}
          data-oembed-provider={element.oembed.provider_name}
        >
          {element.oembed.html}
        </div>
      );
    case Elements.hyperlink:
      var target = element.data.target
        ? 'target="' + element.data.target + '" rel="noopener"'
        : '';
      linkUrl = PrismicDOM.Link.url(element.data, linkResolver);
      return (
        <a key={key} className="body-text-link hyperlink" href={linkUrl}>
          {children}
        </a>
      );
    case Elements.label:
      return (
        <span
          key={key}
          className={element.data.label ? element.data.label : ''}
        >
          {children}
        </span>
      );
    case Elements.span:
      if (content) {
        return content.split('\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            const br = React.createElement(
              'br',
              propsWithUniqueKey({}, brIndex)
            );
            return acc.concat([br, p]);
          }
        }, []);
      } else {
        return null;
      }
    default:
      return null;
  }
};

export default htmlSerializer;
