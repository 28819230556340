import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Context } from 'store/provider';
import Link from 'gatsby-link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IconLinkVert } from 'components/ui';
import BrochureIcon from 'images/brochure.svg';
import TourIcon from 'images/tour.svg';
import SchoolIcon from 'images/school.svg';
import FacilitiesIcon from 'images/localArea.svg';
import CardGiftcardIcon from 'images/present.svg';
//import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import VideoCam from 'images/video-camera-svgrepo-com.svg';
import TestIcon from 'images/reshot-icon-video-player.svg';

import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  outerBox: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  line: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  blockTitle: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
});

function DownloadsAndLinks(props) {
  const [state, dispatch] = useContext(Context);
  const {
    brochure,
    brochure_text,
    secondary_brochure,
    secondary_brochure_text,
    tour,
    schools,
    facilities,
    doc_uid,
    third_brochure,
    incentives,
    testimonial_video,
    development_video,
    classes,
  } = props;
  const active = brochure.url || schools.url || facilities.url ? true : false;
  if (!active) return null;

  var third_brochure_data;

  if (third_brochure) {
    third_brochure_data = {
      link_type: 'Web',
      url: 'https://prismic-io.s3.amazonaws.com/rectory-housing/da058257-1f67-46cc-b300-83fef6dc02c4_9430_4pp_A4_LANDSCAPE_Mini+Brochures_Woolstone_v6_aw_web.pdf',
    };
  }

  console.log(testimonial_video);

  return (
    <Grid container>
      <Box component={Grid} item xs alignItems="center" display="flex">
        <Box className={classes.line} />
      </Box>
      <Grid item xs={11} md={10} lg={7} xl={5}>
        <Box className={classes.outerBox} px={4} py={8}>
          <Box component={Grid} container alignItems="center">
            <Grid item xs={12} md={6}>
              <Box mb={{ xs: 6, md: 0 }}>
                <Typography variant="h3" className={classes.blockTitle}>
                  Downloads & <br /> Useful links
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              >
                {testimonial_video && testimonial_video.embed_url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={testimonial_video}
                      icon={<TestIcon />}
                      title="Testimonial<br />Video"
                    />
                  </Box>
                )}
                {development_video && development_video.embed_url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={development_video}
                      icon={<VideoCam />}
                      title="Development<br />Video"
                    />
                  </Box>
                )}
                {brochure && brochure.url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={brochure}
                      icon={<BrochureIcon />}
                      title={
                        brochure_text ? brochure_text : 'Download<br />Brochure'
                      }
                    />
                  </Box>
                )}
                {secondary_brochure && secondary_brochure.url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={secondary_brochure}
                      icon={<BrochureIcon />}
                      title={
                        secondary_brochure_text
                          ? secondary_brochure_text
                          : 'Download<br />Brochure'
                      }
                    />
                  </Box>
                )}
                {third_brochure && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={third_brochure_data}
                      icon={<BrochureIcon />}
                      title="Plot 10<br />at Six Acres"
                    />
                  </Box>
                )}
                {tour && tour.url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={tour}
                      icon={<TourIcon />}
                      title="3D<br />Tour"
                    />
                  </Box>
                )}
                {schools && schools.url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={schools}
                      icon={<SchoolIcon />}
                      title="Local<br />Schools"
                    />
                  </Box>
                )}
                {facilities && facilities.url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={facilities}
                      icon={<FacilitiesIcon />}
                      title="Area<br />Facilities"
                    />
                  </Box>
                )}
                {incentives && incentives.url && (
                  <Box mx={2} mb={{ xs: 4, md: 0 }}>
                    <IconLinkVert
                      data={incentives}
                      icon={<CardGiftcardIcon />}
                      title="Incentives"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={5}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Link
                  to="/contact-us"
                  onClick={() =>
                    dispatch({ type: 'SET_DEVELOPMENT', payload: doc_uid })
                  }
                  style={{
                    textDecoration: 'none',
                    color: 'rgb(190, 161, 105)',
                    fontSize: '1.5rem',
                    border: '1px solid #bda068',
                    padding: '10px 25px',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Enquire Now
                </Link>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Box component={Grid} item xs alignItems="center" display="flex">
        <Box className={classes.line} />
      </Box>
    </Grid>
  );
}

DownloadsAndLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  brochure: PropTypes.object,
  brochure_text: PropTypes.string,
  secondary_brochure: PropTypes.object,
  secondary_brochure_text: PropTypes.string,
  tour: PropTypes.object,
  schools: PropTypes.object,
  facilities: PropTypes.object,
  doc_uid: PropTypes.string,
  third_brochure: PropTypes.bool,
  incentives: PropTypes.object,
};

export default withStyles(styles)(DownloadsAndLinks);
