import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import ReactPlayer from 'react-player';

import Play from 'images/play.svg';
import CloseIcon from '@material-ui/icons/Close';

import { useWindowWidth } from '@react-hook/window-size/throttled';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

const styles = {
  videoWrapper: {
    zIndex: '1000',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    top: '30px',
    right: '20px',
  },
  icon: {
    cursor: 'pointer',
    color: '#fff',
  },
  button: {
    cursor: 'pointer',
    background: 'transparent',
    border: 0,
    padding: 0,
  },
};

function Video(props) {
  const { data, classes } = props;
  const [aspectRatio, setAspectRatio] = useState('2.5:1');
  const [open, setOpen] = useState(false);
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    if (onlyWidth <= 900) {
      setAspectRatio('1:2');
    }
    if (onlyWidth >= 901) {
      setAspectRatio('2.5:1');
    }
  }, [onlyWidth]);

  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      {open && (
        <Box position="fixed" className={classes.videoWrapper}>
          <Box position="absolute" className={classes.close}>
            <button className={classes.button} onClick={() => setOpen(!open)}>
              <CloseIcon className={classes.icon} />
            </button>
          </Box>
          <ReactPlayer width="90vw" height="60vh" url={data.video.embed_url} />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="primary.main"
        p={{ xs: 2, sm: 3, md: 4 }}
      >
        {data.video_thumbnail && data.video_thumbnail.url && (
          <React.Fragment>
            <Box position="absolute">
              <button className={classes.button} onClick={() => setOpen(!open)}>
                <Play />
              </button>
            </Box>
            <img
              className="w-100"
              src={`${data.video_thumbnail.url}&ar=${aspectRatio}&fit=crop`}
              alt={data.video_thumbnail.alt ? data.video_thumbnail.alt : ''}
            />
          </React.Fragment>
        )}
      </Box>
      {data.video_caption && data.video_caption.raw && (
        <Box component="div" mt={6} color="text.secondary" align="center">
          <RichText
            render={data.video_caption.raw}
            htmlSerializer={htmlSerializer}
          />
        </Box>
      )}
    </Container>
  );
}

Video.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Video);
