import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ContactFormElem } from 'components/ui';
import Ident from 'images/ident.svg';

export const Background = styled.div`
  min-height: 600px;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.image})`};
`;

const styles = {
  identWrapper: {
    transform: 'translateY(-50%)',
  },
  icon: {
    width: '80px',
    height: '80px',
  },
};

function ContactForm(props) {
  const { data, classes } = props;
  return (
    <StaticQuery
      query={graphql`
        query DevelopmentsListQuery {
          allPrismicDevelopment(
            filter: { data: { archive_status: { eq: true } } }
            sort: { order: ASC, fields: data___title___text }
          ) {
            edges {
              node {
                data {
                  title {
                    text
                  }
                  archive_status
                  development_id
                }
                uid
              }
            }
          }
        }
      `}
      render={(queryData) => (
        <Grid container>
          <Box
            position="absolute"
            width="100%"
            display="flex"
            justifyContent="center"
            className={classes.identWrapper}
          >
            <Ident className={classes.icon} />
          </Box>
          {data.image && data.image.url && (
            <Box
              component={Grid}
              item
              xs={12}
              lg={6}
              display="flex"
              order={{ xs: 0, lg: data.image_alignment ? 1 : 0 }}
            >
              <Background image={`${data.image.url}`} />
            </Box>
          )}
          {data.recipient_email && (
            <Box
              component={Grid}
              item
              xs={12}
              lg={6}
              display="flex"
              bgcolor="background.primary"
            >
              <Box p={{ xs: 5, md: 10 }}>
                <ContactFormElem developments={queryData} />
              </Box>
            </Box>
          )}
        </Grid>
      )}
    />
  );
}

ContactForm.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
