import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Link from 'gatsby-link';
import Typography from '@material-ui/core/Typography';
import linkResolver from 'helpers/linkResolver';

const LinkText = (title) => {
  return (
    <Typography
      component="div"
      variant="button"
      align="center"
      color="textSecondary"
      style={{ lineHeight: '1rem' }}
      dangerouslySetInnerHTML={{ __html: title.title }}
    />
  );
};

function IconLinkVert(props) {
  const { title, icon, data } = props;
  const [showOverlay, setShowOverlay] = useState(false); // State to manage overlay visibility

  // Function to toggle overlay visibility
  const toggleOverlay = () => {
    console.log('Clicking');
    setShowOverlay(!showOverlay);
  };

  const externalLink =
    data.link_type === 'Web' || data.link_type === 'Media' ? true : false;

  return (
    <Box display="flex" flexDirection="column">
      {icon && (
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          {icon}
        </Box>
      )}
      {/* Checking if data contains embed_url */}
      {data.embed_url ? (
        // If embed_url exists, display title as a link to toggle overlay
        <Box
          component="button" // Using Link component as a button to handle click event
          onClick={toggleOverlay} // Click event toggles overlay
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            border: 0,
            background: 'transparent',
            padding: 0,
            margin: 0,
          }}
        >
          <LinkText title={title} />
        </Box>
      ) : externalLink ? (
        // If data doesn't contain embed_url, and it's an external link, render an anchor tag
        <a
          href={data.url}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <LinkText title={title} />
        </a>
      ) : (
        // If it's not an external link and doesn't contain embed_url, render an internal Link
        <Link
          to={`${data.url ? linkResolver(data) : ''}`}
          style={{ textDecoration: 'none' }}
        >
          <LinkText title={title} />
        </Link>
      )}
      {/* Conditionally render overlay based on showOverlay state */}
      {showOverlay && (
        <button
          onClick={toggleOverlay} // Clicking the button toggles the overlay
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              toggleOverlay(); // Close the overlay when the Escape key is pressed
            }
          }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            zIndex: 9999, // Ensure the overlay is above other elements
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            padding: 0,
            margin: 0,
          }}
        >
          <div
            style={{
              width: '80%',
              height: '80%',
            }}
          >
            {/* Insert your overlay content here */}
            <iframe
              src={data.embed_url}
              title="Embedded Video"
              style={{ width: '100%', height: '100%', border: 'none' }} // Adjust size as needed
            />
          </div>
        </button>
      )}
    </Box>
  );
}

IconLinkVert.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default IconLinkVert;
