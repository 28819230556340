import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Context } from 'store/provider';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import distance from 'helpers/geoDistance';
import SearchIcon from '@material-ui/icons/Search';

import './SearchBar.css';

function SearchBar() {
  const [state, dispatch] = useContext(Context);
  const [searchOptions, setSearchOptions] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState('');

  const data = useStaticQuery(graphql`
    query allDevelopmentsQuery {
      allPrismicDevelopment(
        filter: {
          data: {
            archive_status: { eq: true }
            show_development_in_search: { eq: true }
          }
        }
      ) {
        edges {
          node {
            uid
            type
            data {
              archive_status
              forthcoming_development
              listing_images {
                image {
                  url
                  alt
                }
              }
              development_logo {
                url
                alt
              }
              title {
                html
                raw
                text
              }
              development_short_detail {
                html
                raw
                text
              }
              development_status {
                html
                raw
                text
              }
              development_location {
                html
                raw
                text
              }
              geo_location {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  `);

  const geoLocateOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const updateLocation = (e) => {
    dispatch({
      type: 'SET_SEARCH_LOCATION_READABLE',
      payload: e.target.value,
    });
    dispatch({
      type: 'SET_SEARCH_GEOLOCATION',
      payload: { lat: '', lng: '' },
    });
    setNoResultsMessage('');
    if (e.target.value.length > 1) {
      axios
        .get(
          `https://api.opencagedata.com/geocode/v1/json?q=${e.target.value}&key=${process.env.OPENCAGE_API_KEY}&language=en&pretty=1&no_annotations=1&countrycode=gb&min_confidence=2`
        )
        .then(function (response) {
          // handle success
          setSearchOptions(response.data.results);
        })
        .catch(function (error) {
          // handle error
          setSearchOptions([]);
        })
        .then(function () {
          // always executed
        });
    } else {
      setSearchOptions([]);
    }
  };

  const handleSetSelectedLocation = (val) => {
    setSearchOptions([]);
    dispatch({
      type: 'SET_SEARCH_LOCATION_READABLE',
      payload: val.formatted,
    });
    dispatch({
      type: 'SET_SEARCH_GEOLOCATION',
      payload: { lat: val.geometry.lat, lng: val.geometry.lng },
    });
  };

  const handleSetSelectedRadius = (val) => {
    dispatch({ type: 'SET_SEARCH_RADIUS', payload: val.target.value });
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === 'prompt') {
            navigator.geolocation.getCurrentPosition(
              success,
              errors,
              geoLocateOptions
            );
          } else if (result.state === 'denied') {
            // If denied then you have to show instructions to enable location
            alert(
              'Please enable location services in your browser so we can retrieve your location'
            );
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert('Sorry your location is not available!');
    }
  };

  const success = (pos) => {
    var crd = pos.coords;
  };

  const errors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const handleSubmit = () => {
    dispatch({ type: 'SET_LOADER', payload: true });
    let initiateList = data.allPrismicDevelopment.edges.filter(
      (item) => item.node.data.geo_location
    );

    let results = initiateList.filter((item) => {
      const geoResult = item.node.data.geo_location;
      const distanceCalc = distance(
        geoResult.latitude,
        geoResult.longitude,
        state.searchGeoLocation.lat,
        state.searchGeoLocation.lng
      );
      if (state.searchRadius >= distanceCalc) {
        item.node.data.distance = distanceCalc;
        return true;
      }
      return false;
    });

    if (!results || results.length == 0) {
      setNoResults(true);
    } else {
      dispatch({ type: 'SET_SEARCH_RESULTS', payload: results });
      dispatch({ type: 'SET_LOADER', payload: false });
      setNoResultsMessage('');
      navigate('/property-search');
    }
  };

  useEffect(() => {
    const val =
      !state.searchGeoLocation.lat && !state.searchGeoLocation.lng
        ? false
        : true;
    setAllowSubmit(val);
  }, [state]);

  useEffect(() => {
    if (noResults === false) return;
    const timer = setTimeout(() => {
      dispatch({ type: 'SET_LOADER', payload: false });
      setNoResults(false);
      setNoResultsMessage(
        "Sorry, we couldn't find any results for your search critera. Please widen your search or browse all developments"
      );
    }, 3000);
    return () => clearTimeout(timer);
  }, [noResults]);

  return (
    <Container maxWidth="lg">
      <Box
        style={{
          backgroundColor: '#282827',
          borderLeft: '1px solid rgb(151, 128, 84)',
          borderRight: '1px solid rgb(151, 128, 84)',
          borderBottom: '1px solid rgb(151, 128, 84)',
          borderTop: '1px solid rgb(151, 128, 84)',
        }}
        px={4}
        py={6}
        mt={4}
      >
        <Box
          component={Grid}
          container
          alignItems={{ xs: 'normal', md: 'center' }}
          spacing={6}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Grid item xs={12} md={3}>
            <Typography component="p" variant="h5">
              FIND A DEVELOPMENT
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box position="relative">
              <Box display="flex" flexDirection="column">
                <Typography
                  component="label"
                  variant="button"
                  htmlFor="location"
                >
                  Enter a location (e.g Town or Postcode)
                  {/*<button onClick={getCurrentLocation}>Locate</button>*/}
                </Typography>
                <input
                  type="text"
                  id="location"
                  name="location"
                  onChange={updateLocation}
                  value={state.searchLocationReadable}
                  autoComplete="off"
                  className="standard-input"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column">
              <Typography component="label" variant="button" htmlFor="radius">
                Search Radius
              </Typography>
              <select
                id="radius"
                name="radius"
                onChange={handleSetSelectedRadius}
                onBlur={handleSetSelectedRadius}
                defaultValue={
                  state.searchRadius
                    ? state.searchRadius
                    : state.searchRadiusOptions[0]
                }
                className="standard-input"
              >
                {state.searchRadiusOptions.map((item, key) => {
                  return (
                    <option key={key} value={item}>
                      {item} Miles
                    </option>
                  );
                })}
              </select>
            </Box>
          </Grid>
          <Grid
            item
            xs
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box mt={4}>
              <button
                onClick={handleSubmit}
                disabled={!allowSubmit}
                style={{
                  cursor: 'pointer',
                  background: 'transparent',
                  border: 0,
                  padding: 0,
                  margin: 0,
                }}
              >
                <Box display={{ xs: 'none', md: 'block' }}>
                  <SearchIcon style={{ display: 'block', color: '#988154' }} />
                </Box>
                <Box display={{ xs: 'block', md: 'none' }}>
                  <Typography
                    component="p"
                    variant="h5"
                    color="textSecondary"
                    align="center"
                  >
                    Search
                  </Typography>
                </Box>
              </button>
            </Box>
          </Grid>
        </Box>
        {noResultsMessage && (
          <Grid container spacing={6}>
            <Grid item xs={3} />
            <Grid item xs={8}>
              <Typography component="p" style={{ color: 'red' }}>
                {noResultsMessage}
              </Typography>
            </Grid>
          </Grid>
        )}
        {searchOptions.length > 0 && (
          <Grid container spacing={6}>
            <Grid item xs={3} />
            <Grid item xs={4}>
              <Box mb={2}>
                <Typography
                  component="p"
                  variant="body2"
                  style={{
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    color: '#fff',
                  }}
                >
                  Please select a location from the list below
                </Typography>
              </Box>
              {searchOptions.length > 0 && (
                <Box component="ul" p={0} m={0}>
                  {searchOptions.map((item, key) => {
                    return (
                      <Box key={key} component="li" mb={1}>
                        <Typography
                          component="button"
                          className="select-location-button"
                          variant="h6"
                          align="left"
                          color="textPrimary"
                          onClick={() => handleSetSelectedLocation(item)}
                          style={{
                            background: 'transparent',
                            border: 0,
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'red',
                            },
                          }}
                        >
                          {item.formatted}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
}

export default SearchBar;
