import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

function IconBulletList(props) {
  const { data, items } = props;
  const hasImage = data.image && data.image.url ? true : false;
  const bulletsGrid = data.image && data.image.url ? 8 : 12;
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <div className="spaced-grid-wrapper">
        <Box
          component={Grid}
          container
          spacing={6}
          display="flex"
          alignItems="center"
        >
          <Grid item xs={12} lg={bulletsGrid}>
            <div className="spaced-grid-wrapper">
              <Grid container spacing={6}>
                {items.map((item, key) => {
                  return (
                    <Grid key={key} item xs={12} sm={6}>
                      <Box display="flex" alignItems="center">
                        {item.icon && item.icon.url && (
                          <Box mr={3}>
                            <img
                              src={item.icon.url}
                              alt={item.icon.alt ? item.icon.alt : ''}
                            />
                          </Box>
                        )}
                        {item.bullet_content && item.bullet_content.raw && (
                          <Box component="div" color="text.secondary">
                            <RichText
                              render={item.bullet_content.raw}
                              htmlSerializer={htmlSerializer}
                            />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
          {hasImage && (
            <Grid item xs={12} lg={4}>
              <img
                className="w-100"
                src={data.image.url}
                alt={data.image.alt ? data.image.alt : ''}
              />
            </Grid>
          )}
        </Box>
      </div>
    </Container>
  );
}

IconBulletList.propTypes = {
  data: PropTypes.object,
  items: PropTypes.array.isRequired,
};

export default IconBulletList;
