import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

function ImageSlideshow(props) {
  const { items } = props;
  const breakPointsValues = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1280, itemsToShow: 2, itemsToScroll: 1 },
  ];
  return (
    <Container
      maxWidth={process.env.CONTAINER_WIDTH}
      className="image-slideshow"
    >
      <Carousel breakPoints={breakPointsValues} showArrows={false}>
        {items.map((item, key) => {
          return (
            <Box key={key}>
              <Box px={{ xs: 0, lg: 2 }}>
                <img
                  style={{ width: '100%' }}
                  src={`${item.image.url}&w=3000&ar=2:1.5&fit=crop`}
                  alt={item.image.alt}
                />
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </Container>
  );
}

ImageSlideshow.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ImageSlideshow;
