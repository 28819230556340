import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import { StaticQuery, graphql } from 'gatsby';
import { withStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import linkResolver from 'helpers/linkResolver';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import LinkUnderline from 'images/underline.svg';

const styles = (theme) => ({
  link: {
    [theme.breakpoints.down('lg')]: {},
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&.active + .link-underline': {
      opacity: 1,
    },
    '&:hover + .link-underline': {
      opacity: 1,
    },
  },
  menuText: {
    marginLeft: '0.5rem',
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
  underline: {
    opacity: 0,
    transition: 'all 0.3s ease-in-out',
  },
});

function Nav(props) {
  const { classes } = props;
  const [state, dispatch] = useContext(Context);

  const menuToggle = () => {
    dispatch({ type: 'SET_MENU', payload: !state.menu });
  };

  return (
    <StaticQuery
      query={graphql`
        query MainMenuQuery {
          prismicGlobalLayout {
            data {
              main_menu {
                link_name
                link {
                  uid
                  type
                  id
                  link_type
                  raw
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <ul>
            <Box component="li" display="flex" flexDirection="column">
              <Box>
                <button
                  className={`menu ${state.menu ? 'opened' : ''}`}
                  onClick={menuToggle}
                  aria-label="Main Menu"
                >
                  <svg width="30" height="30" viewBox="0 0 100 100">
                    <path
                      className="line line1"
                      d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                    />
                    <path className="line line2" d="M 20,50 H 80" />
                    <path
                      className="line line3"
                      d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                    />
                  </svg>
                  <Box display={{ xs: 'none', lg: 'none' }}>
                    <Typography
                      component="p"
                      variant="button"
                      color="textSecondary"
                      className={classes.menuText}
                    >
                      MENU
                    </Typography>
                  </Box>
                </button>
              </Box>
              <LinkUnderline className={classes.visibilityHidden} />
            </Box>
            {data.prismicGlobalLayout.data.main_menu && (
              <React.Fragment>
                {data.prismicGlobalLayout.data.main_menu.map((item, key) => {
                  if (item.link.link_type === 'Web') {
                    return (
                      <Box
                        component="li"
                        display={{ xs: 'none', lg: 'flex' }}
                        flexDirection="column"
                        alignItems="center"
                        key={key}
                      >
                        <Typography
                          component="a"
                          variant="button"
                          href={`${item.link.raw.url}`}
                          className={classes.link}
                          activeClassName="active"
                        >
                          {item.link_name}
                        </Typography>
                        <LinkUnderline
                          className={`${classes.underline} link-underline`}
                        />
                      </Box>
                    );
                  }
                  return (
                    <Box
                      component="li"
                      display={{ xs: 'none', lg: 'flex' }}
                      flexDirection="column"
                      alignItems="center"
                      key={key}
                    >
                      <Typography
                        component={Link}
                        variant="button"
                        to={`${item.link.type ? linkResolver(item.link) : ''}`}
                        className={classes.link}
                        activeClassName="active"
                      >
                        {item.link_name}
                      </Typography>
                      <LinkUnderline
                        className={`${classes.underline} link-underline`}
                      />
                    </Box>
                  );
                })}
              </React.Fragment>
            )}
          </ul>
        </Container>
      )}
    />
  );
}

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Nav);
