import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';

const styles = {
  inputField: {
    width: '100%',
    padding: '10px',
  },
  submit: {
    background: '#BEA169',
    boxShadow: 'none',
    border: 0,
    cursor: 'pointer',
    lineHeight: 'initial',
    padding: '10px 30px',
    color: '#fff',
    fontSize: '1.2rem',
    '&.disabledBtn': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
  cantSubmitError: {
    visibility: 'hidden',
    fontWeight: 'bold',
    '&.active': {
      visibility: 'visible',
    },
  },
  required: {
    color: '#dc3545',
  },
  requiredContactMethods: {
    color: '#dc3545',
    visibility: 'hidden',
    '&.visible': {
      visibility: 'visible',
    },
  },
  capitilse: {
    textTransform: 'capitalize',
  },
  submittingOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(62, 60, 62, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function ContactFormElem(props) {
  const { classes, developments } = props;
  const [state, dispatch] = useContext(Context);

  const titleOptions = ['Mr', 'Mrs', 'Miss', 'Ms'];
  const fields = [
    'first_name',
    'last_name',
    //'postcode',
    'contact_number',
    'email_address',
  ];
  const referrer = [
    'Website',
    'Property Portal',
    'Estate Agent',
    'Signage',
    'Local Knowledge',
    'Newspaper',
    'Previous Purchaser',
    'Recommendation',
    'Social Media',
  ];
  const contactMethods = ['email', 'phone', 'SMS'];
  const [values, setValues] = useState({
    title: 'Mr',
    first_name: '',
    last_name: '',
    //postcode: '',
    contact_number: '',
    email_address: '',
    development: '',
    development_name: '',
    additional_comments: '',
  });
  const [contactMethodsChoosen, setContactMethodsChoosen] = useState({
    email: true,
    phone: false,
    post: false,
    SMS: false,
  });
  const [contactMethodsError, setContactMethodsError] = useState(false);
  const [cannotPressSubmit, setCannotPressSubmit] = useState(false);
  const [showCompletionMessage, setShowCompletionMessage] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const updateValue = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const updateDevelopmentValue = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      development_name: e.target[e.target.options.selectedIndex].innerHTML,
    });
  };

  const updateContactMethodValue = (e) => {
    setContactMethodsChoosen({
      ...contactMethodsChoosen,
      [e.target.name]: !contactMethodsChoosen[e.target.name],
    });
  };

  const submit = () => {
    setSubmitting(true);
    let formData = new FormData();
    formData.append(
      'Full Name',
      values.title + ' ' + values.first_name + ' ' + values.last_name
    );
    //formData.append('Postcode', values.postcode);
    formData.append('Contact Number', values.contact_number);
    formData.append('Email', values.email_address);
    formData.append('Development', values.development_name);
    formData.append('Message', values.additional_comments);

    {
      contactMethods.map((item) => {
        if (contactMethodsChoosen[item] === true) {
          formData.append('Contact via ' + item, 'Yes');
        }
      });
    }

    axios({
      method: 'POST',
      url: `${process.env.FORMSPREE_ENDPOINT}`,
      data: formData,
    })
      .then(() => {
        setSubmitting(false);
        setValues({
          title: 'Mr',
          first_name: '',
          last_name: '',
          //postcode: '',
          contact_number: '',
          email_address: '',
          development: '',
          development_name: '',
          additional_comments: '',
        });
        setContactMethodsChoosen({
          email: true,
          phone: false,
          post: false,
          SMS: false,
        });
        setShowCompletionMessage(true);
      })
      .catch((r) => {
        console.log(r.response.data.error);
      });

    // POST data to Contact Builder API

    var email_sub = contactMethodsChoosen.email === true ? 'normal' : 'unsub';
    var post_sub = contactMethodsChoosen.post === true ? 'normal' : 'unsub';
    var sms_sub = contactMethodsChoosen.SMS === true ? 'normal' : 'unsub';
    var phone_sub = contactMethodsChoosen.phone === true ? 'normal' : 'unsub';

    var developmentId = values.development ? values.development : 0;

    var xmlBodyStr =
      `<?xml version="1.0" ?>
        <request>
        <password>` +
      process.env.CONTACT_BUILDER_API_PASSWORD +
      `</password>
        <action>add</action>
          <contact>
          <prefix>` +
      values.title +
      `</prefix>
          <firstname>` +
      values.first_name +
      `</firstname>
          <lastname>` +
      values.last_name +
      `</lastname>
          <email>` +
      values.email_address +
      `</email>
      <mobilephone>` +
      values.contact_number +
      `</mobilephone>
          <adm_email_status>` +
      email_sub +
      `</adm_email_status>
          <adm_post_status>` +
      post_sub +
      `</adm_post_status>
          <adm_sms_status>` +
      sms_sub +
      `</adm_sms_status>
          <adm_telephone_status>` +
      phone_sub +
      `</adm_telephone_status>
          <dev1>` +
      developmentId +
      `</dev1>
            <interaction>
              <interactiontitle>Contact Form</interactiontitle>
              <interactiondesc>` +
      values.additional_comments +
      `</interactiondesc>
            </interaction>
          </contact>
        </request>
    `;

    var config = {
      headers: { 'Content-Type': 'text/xml' },
    };

    axios
      .post(process.env.CONTACT_BUILDER_API_ENDPOINT, xmlBodyStr, config)
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const cannotSubmit = () => {
    setCannotPressSubmit(true);
  };

  const submitDisabled =
    !values.title ||
    !values.first_name ||
    !values.last_name ||
    //!values.postcode ||
    //!values.contact_number ||
    !values.email_address ||
    !values.development ||
    values.development == '0001';

  useEffect(() => {
    if (
      !contactMethodsChoosen.email &&
      !contactMethodsChoosen.phone &&
      !contactMethodsChoosen.post &&
      !contactMethodsChoosen.SMS
    ) {
      setContactMethodsError(true);
    } else {
      setContactMethodsError(false);
    }
  }, [contactMethodsChoosen]);

  useEffect(() => {
    if (state.development) {
      setValues({
        ...values,
        development: state.development,
      });
      dispatch({ type: 'SET_DEVELOPMENT', payload: '' });
    }
  }, []);

  return (
    <Box position="relative">
      {submitting && (
        <Box className={classes.submittingOverlay}>
          <CircularProgress />
        </Box>
      )}
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography component="span" variant="body1">
              <label htmlFor="title">
                Title <span className={classes.required}>*</span>
              </label>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <select
              id="title"
              name="title"
              className={classes.inputField}
              onBlur={updateValue}
            >
              {titleOptions.map((item, key) => {
                return (
                  <option key={key} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </Grid>
        </Grid>
      </Box>
      {fields.map((item, key) => {
        return (
          <Box component={Grid} container key={key} display="flex" mb={2}>
            <Grid item xs={12} md={3}>
              <label htmlFor={item} className="capatilise">
                <Typography component="span" variant="body1">
                  {item.replace('_', ' ')}{' '}
                  {item !== 'contact_number' && (
                    <span className={classes.required}>*</span>
                  )}
                </Typography>
              </label>
            </Grid>
            <Grid item xs={12} md>
              <input
                type="text"
                className={classes.inputField}
                id={item}
                name={item}
                value={values[item]}
                onChange={updateValue}
              />
            </Grid>
          </Box>
        );
      })}
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography component="span" variant="body1">
              <label htmlFor="development">
                Development<span className={classes.required}>*</span>
              </label>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <select
              id="development"
              name="development"
              className={classes.inputField}
              onChange={updateDevelopmentValue}
              onBlur={updateDevelopmentValue}
              value={values.development}
            >
              <option value="0001">Select development</option>
              {developments.allPrismicDevelopment.edges.map((item, key) => {
                return (
                  <option key={key} value={item.node.data.development_id}>
                    {item.node.data.title.text}
                  </option>
                );
              })}
            </select>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography component="span" variant="body1">
              <label htmlFor="additional_comments">Message</label>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <textarea
              className={classes.inputField}
              id="additional_comments"
              name="additional_comments"
              onChange={updateValue}
              value={values.additional_comments}
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={4}>
        <Typography component="p" variant="body2">
          By submitting this form, you confirm you are happy for a member of our
          Sales Team to contact you. For future correspondence please confirm
          your preferred methods of contact:
        </Typography>
      </Box>
      <Box display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }}>
        {contactMethods.map((item, key) => {
          return (
            <Box key={key} mr={4} display="flex" alignItems="center">
              <Box mr={1}>
                <input
                  type="checkbox"
                  id={item}
                  name={item}
                  checked={contactMethodsChoosen[item]}
                  onChange={updateContactMethodValue}
                />
              </Box>
              <label htmlFor={item}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.capitilse}
                >
                  {item}
                </Typography>
              </label>
            </Box>
          );
        })}
      </Box>
      <Box>
        <Typography
          component="p"
          variant="caption"
          className={`${classes.requiredContactMethods} ${
            contactMethodsError ? 'visible' : 'hidden'
          }`}
        >
          You must choose at least one contact method to submit this form
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent={{ xs: 'center', md: 'flex-end' }}
      >
        <button
          className={`${classes.submit} ${
            submitDisabled ? 'disabledBtn' : 'active'
          }`}
          onClick={submitDisabled ? cannotSubmit : submit}
        >
          SEND
        </button>
        <Box pt={4}>
          {showCompletionMessage && (
            <Typography component="p" variant="body2" align="center">
              Thank you for getting in touch, a member of our team will be in
              contact soon
            </Typography>
          )}
          <Typography
            component="p"
            variant="body2"
            align="center"
            className={`${classes.cantSubmitError} ${
              cannotPressSubmit ? 'active' : 'hidden'
            }`}
          >
            Please complete all required fields
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ContactFormElem.propTypes = {
  classes: PropTypes.object.isRequired,
  developments: PropTypes.object,
};

export default withStyles(styles)(ContactFormElem);
