import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Ident from 'images/ident.svg';

import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'helpers/htmlSerializer';

function TitleAndText(props) {
  const { data } = props;
  var titleMarginBottom = data.display_icon_below_title ? 0 : 4;
  const grid = data.content_grid_width ? Number(data.content_grid_width) : 6;
  if (!data.content.html) {
    titleMarginBottom = 0;
  }
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      {data.title && data.title.raw && (
        <Box component={Grid} justifyContent={`${data.alignment}`} container>
          <Grid item xs={12} md={grid}>
            <Box
              component="div"
              align={`${data.alignment}`}
              mb={titleMarginBottom}
            >
              <RichText
                render={data.title.raw}
                htmlSerializer={htmlSerializer}
              />
            </Box>
          </Grid>
        </Box>
      )}
      {data.content && data.content.raw && (
        <React.Fragment>
          {data.display_icon_below_title && (
            <Box align={`${data.alignment}`} my={4}>
              <Ident />
            </Box>
          )}
          <Box component={Grid} justifyContent={`${data.alignment}`} container>
            <Grid item xs={12} md={grid}>
              <Box
                component="div"
                color="text.secondary"
                align={`${data.alignment}`}
              >
                <RichText
                  render={data.content.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Box>
            </Grid>
          </Box>
        </React.Fragment>
      )}
    </Container>
  );
}

TitleAndText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TitleAndText;
