import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

function ContactBlock(props) {
  const { data } = props;
  return (
    <Container maxWidth={process.env.CONTAINER_WIDTH}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box display="flex" mb={4} mx={4}>
              <Box mr={4}>
                <PhoneIcon style={{ color: '#fff' }} />
              </Box>
              <div>
                <Typography component="h5" color="textPrimary">
                  {data[0].phone_number}
                </Typography>
                <Typography component="h6" color="textSecondary">
                  {data[0].opening_hours}
                </Typography>
              </div>
            </Box>
            <Box display="flex" mx={4}>
              <Box mr={4}>
                <MailOutlineIcon style={{ color: '#fff' }} />
              </Box>
              <div>
                <Typography
                  component="a"
                  href={`mailto:${data[0].email_address}`}
                  variant="body1"
                  color="textPrimary"
                >
                  {data[0].email_address}
                </Typography>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        style={{ height: '1px', backgroundColor: '#BEA169', width: '100%' }}
        my={10}
      />
      <Box mb={6}>
        <Typography
          component="h3"
          variant="h3"
          color="textPrimary"
          align="center"
        >
          Emergency after hours
        </Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <Typography component="p" variant="body2" color="textSecondary">
            Please call our After Hours Emergency Support Line if you experience
            any of the following issues outside of business hours:
          </Typography>
          <br />
          <Typography component="p" variant="body2" color="textSecondary">
            • Complete loss of heat  during winter months
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary">
            • Complete loss of electricity{' '}
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary">
            • Complete loss of water or sewer blockage{' '}
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary">
            • Major plumbing leaks{' '}
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary">
            • Security issues with external doors Additionally, we can provide
            advice for you to help mitigate further damage until After Sales
            Department reopens with the following item:
          </Typography>
          <br />
          <Typography component="p" variant="body2" color="textSecondary">
            • Water penetration into your home
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography component="p" variant="body2" color="textSecondary">
            During these times, an out of hours  representative will respond
            within one hour. Our aim will be to have the original contractor
            correct the problem
          </Typography>
          <br />
          <Typography component="p" variant="body2" color="textSecondary">
            If this cannot be arranged, we will help you manage the situation
            until we can address it during the next business day.
          </Typography>
          <br />
          <Typography component="p" variant="body2" color="textSecondary">
            Any item not mentioned is not considered an emergency
          </Typography>
          <br />
          <Typography
            component="p"
            variant="body1"
            color="textSecondary"
            style={{ fontWeight: 'bold' }}
            gutterBottom
          >
            Important
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary">
            For fire, gas leaks, and carbon monoxide leaks contact your local
            Fire Department or  British Gas on 0800 111 999
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography component="p" variant="body2" color="textSecondary">
            <Typography component="span" variant="body2" color="textPrimary">
              0121 206 2863
            </Typography>{' '}
            5:00PM – 9:00AM, Monday – Friday 24 hours service  - Weekends and
            Holidays
          </Typography>
          <br />
          <Typography component="p" variant="body2" color="textSecondary">
            For all sites attended to by{' '}
            <strong>Rolec Plumbing Group services</strong> please contact
            their emergency number which is{' '}
            <Typography component="span" variant="body2" color="textPrimary">
              0845 643 9994
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

ContactBlock.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ContactBlock;
