const linkResolver = (doc) => {
  // Pretty URLs for known types
  if (doc.type === 'homepage') return '/';

  if (doc.type === 'development') return `/development/${doc.uid}`;

  if (
    doc.type === 'news_article' &&
    doc.data.article_type === 'Community Initiative'
  )
    return `/community-initiatives/${doc.uid}`;

  if (doc.type === 'news_article' && doc.data.article_type === 'News')
    return `/news/${doc.uid}`;

  // Fallback for other types, in case new custom types get created
  if (doc.uid) {
    return `/${doc.uid}`;
  }
  if (doc.url) {
    return `/${doc.url}`;
  }
  return '/';
};

module.exports = linkResolver;
