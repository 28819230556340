import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import Nav from 'components/header/nav';
import SecondaryNav from 'components/header/nav/SecondaryNav';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Logo from 'images/logo.svg';
import { withStyles } from '@material-ui/styles';

import Headroom from 'react-headroom';

const styles = {
  logo: {
    position: 'relative',
    zIndex: '250',
  },
  outerHeaderWrapper: {
    position: 'fixed',
    zIndex: '250',
    width: '100%',
    backgroundColor: '#282827',
  },
};

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

function Header(props) {
  const { classes } = props;
  return (
    <Box className={classes.outerHeaderWrapper}>
      <Container maxWidth={process.env.CONTAINER_WIDTH}>
        <Box
          component={Grid}
          justifyContent="space-between"
          alignItems="center"
          container
          py={3}
        >
          <Grid item xs={2} md={5}>
            <Nav />
          </Grid>
          <Grid item xs="auto">
            <Box display="flex" justifyContent="center">
              <Link to="/">
                <Logo className={classes.logo} />
              </Link>
            </Box>
          </Grid>
          <Box
            component={Grid}
            display="flex"
            alignItems="center"
            item
            xs={2}
            md={5}
          >
            <SecondaryNav />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
